<template>
  <v-container>
    <!--    course-->
    <v-checkbox
        v-model="enable_courses"
        hide-details
        class="shrink mr-2 mt-0"
    >
      <template v-slot:label>
        <h3 class="mt-3">Khóa học</h3>
      </template>
    </v-checkbox>
    <ValidationObserver ref="affiliate_course">
      <h5>
        {{ lang.title.payment }}<span class="red--text mr-4">(*)</span>
      </h5>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col cols="3" class="ma-0 pa-0">
          <ValidationProvider
              rules="required"
              v-slot="{ errors }"
          >
            <v-select
                :disabled="!enable_courses"
                :items="paymentMethod"
                dense
                item-text="label"
                v-model="course.type"
                outlined
                color="primary"
            ></v-select>
          </ValidationProvider>
        </v-col>
        <v-col cols="7" class="ma-0 pa-0">
          <ValidationProvider
              name="price" rules="required|number"
              v-slot="{ errors }"
          >
            <vuetify-money
                v-model="course.amount_money"
                :label="''"
                :placeholder="'Nhập số tiền khuyến mại'"
                :readonly="false"
                :disabled="!enable_courses"
                :outlined="true"
                :clearable="true"
                :valueWhenIsEmpty="''"
                :options="options"
                class="money-box-input"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <!-- describe the discount code information -->
      <div class="text-h6 ml-1">
        {{ lang.title.describe }}
      </div>
      <div class="mt-5">
        <hnr-textarea
            :disabled="!enable_courses"
            v-model="course.description"
            :label="lang.label.describe"
            class="input-textArea"
        ></hnr-textarea>
      </div>
    </ValidationObserver>


    <!--books-->
    <v-checkbox
        v-model="enable_books"
        hide-details
        class="shrink mr-2 mt-0"
    >
      <template v-slot:label>
        <div>
          <h3 class="mt-3">Sách</h3>
        </div>
      </template>
    </v-checkbox>
    <ValidationObserver ref="affiliate_book">
      <div class="text-h6 ml-1">
        {{ lang.title.payment }}<span class="red--text mr-4">(*)</span>
      </div>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col cols="3" class="ma-0 pa-0">
          <ValidationProvider
              name="title"
              rules="required"
              v-slot="{ errors }"
          >
            <v-select
                :disabled="!enable_books"
                :items="paymentMethod"
                class="shrink money-box"
                dense
                item-text="label"
                v-model="book.type"
                outlined
                color="primary"
            ></v-select>
          </ValidationProvider>
        </v-col>
        <v-col cols="7" class="ma-0 pa-0">
          <ValidationProvider
              name="price" rules="required|number"
              v-slot="{ errors }"
          >
            <vuetify-money
                v-model="book.amount_money"
                :label="''"
                :placeholder="'Nhập số tiền khuyến mại'"
                :readonly="false"
                :disabled="!enable_books"
                :outlined="true"
                :clearable="true"
                :valueWhenIsEmpty="''"
                :options="options"
                class="money-box-input"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <!-- describe the discount code information -->
      <div class="text-h6">
        {{ lang.title.describe }}
      </div>
      <div class=" mt-5">
        <hnr-textarea
            :disabled="!enable_books"
            v-model="book.description"
            :label="lang.label.describe"
            class="input-textArea"/>
      </div>
    </ValidationObserver>


    <!--combo-->
    <v-checkbox
        v-model="enable_combos"
        hide-details
        class="shrink mr-2 mt-0"
    >
      <template v-slot:label>
        <div>
          <h3 class="mt-3">Khóa học 1-1</h3>
        </div>
      </template>
    </v-checkbox>
    <ValidationObserver ref="affiliate_combo">
      <div class="text-h6 ml-1">
        {{ lang.title.payment }}<span class="red--text mr-4">(*)</span>
      </div>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col cols="3" class="ma-0 pa-0">
          <ValidationProvider
              name="title"
              rules="required"
              v-slot="{ errors }"
          >
            <v-select
                :disabled="!enable_combos"
                :items="paymentMethod"
                class="shrink money-box"
                dense
                item-text="label"
                v-model="combo.type"
                outlined
                color="primary"
            ></v-select>
          </ValidationProvider>
        </v-col>
        <v-col cols="7" class="ma-0 pa-0">
          <ValidationProvider
              name="price" rules="required|number"
              v-slot="{ errors }"
          >
            <vuetify-money
                v-model="combo.amount_money"
                :label="''"
                :placeholder="'Nhập số tiền khuyến mại'"
                :readonly="false"
                :disabled="!enable_combos"
                :outlined="true"
                :clearable="true"
                :valueWhenIsEmpty="''"
                :options="options"
                class="money-box-input"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <!-- describe the discount code information -->
      <div class="text-h6">
        {{ lang.title.describe }}
      </div>
      <div class=" mt-5">
        <hnr-textarea
            :disabled="!enable_combos"
            v-model="combo.description"
            :label="lang.label.describe"
            class="input-textArea"/>
      </div>
    </ValidationObserver>


    <v-col class="mx-auto text-right" cols="8">
      <hnr-button
          type="submit"
          form="check-form"
          rounded
          width="200"
          text="Tạo mã giới thiệu"
          color="blue"
          @hnrClickEvent="saveCode"
          white-text="true"
      ></hnr-button>
    </v-col>
  </v-container>
</template>

<script>
import HnrButton from "@/components/elements/hnr-button";
import HnrTextarea from "@/components/elements/hnr-textarea";
import {
  CREATE_AFFILIATE_CODE
} from "@/store/user.module";
import {mapGetters} from "vuex";

export default {
  components: {
    HnrTextarea,
    HnrButton
  },

  data: () => ({
    lang: {
      title: {
        course: "Chọn khóa học",
        amountUsed: "Số lần sử dụng",
        code: "Mã giới thiệu",
        describe: "Mô tả",
        money: "Phần trăm(%)",
        all: "Tất cả",
        payment: "Cách thức giảm giá"
      },
      label: {
        course: "Chọn khóa học",
        amountUsed: "0",
        code: "Nhập mã khuyến mại",
        describe: "Nhập mô tả"
      }
    },
    course: {
      type: 1,
      description: '',
      number_of_use :0,
      affiliate_code_type:1,
    },
    combo: {
      type: 1,
      description: '',
      number_of_use :0,
      affiliate_code_type:3,
    },
    book: {
      type: 1,
      description: '',
      number_of_use :0,
      affiliate_code_type:2,
    },
    paymentMethod: [
      {
        value: 2,
        label: "Tiền mặt(đ)"
      },
      {
        value: 1,
        label: "Phần trăm(%)"
      }
    ],
    inputMoney: {
      label: "",
      placeholder: "Nhập mệnh giá theo phần trăm hoặc theo VNĐ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
    },
    options: {
      locale: "pt-BR",
      prefix: "VNĐ$",
      suffix: "",
      length: 100,
      precision: 0,
    },
    enable_courses: false,
    enable_books: false,
    enable_combos: false,
  }),
  computed: {
    ...mapGetters({
      codeInput: "getCode",
      courses: "listCourse"
    })
  },
  methods: {
    async saveCode() {
      let data = {
        user_id: this.$route.query.user_id
      }
      if (!this.enable_courses && !this.enable_books && !this.enable_combos) {
        this.$toasted.error("Chọn kiểu giảm giá!",
            {
              position: 'top-right',
              duration: 3000
            })
        return false
      }
      if (this.enable_courses) {
        let result = this.$refs.affiliate_course.validate()
        if (result) {
          if (this.course.type == 1) {
            if (this.course.amount_money == 0 || this.course.amount_money > 100) {
              this.$toasted.error("Giá trị phải lớn hơn 0 và không được vượt quá 100!",
                  {
                    position: 'top-right',
                    duration: 3000
                  })
              return false
            }
          }

          data.courses = this.course
        }
        else {
          return false
        }
      }
      if (this.enable_books) {
        let result = this.$refs.affiliate_book.validate()
        if (result) {
          if (this.book.type == 1) {
            if (this.book.amount_money == 0 || this.book.amount_money > 100) {
              this.$toasted.error("Giá trị phải lớn hơn 0 và không được vượt quá 100!",
                  {
                    position: 'top-right',
                    duration: 3000
                  })
              return false
            }
          }
          data.books = this.book
        } else {
          return false
        }
      }

      if (this.enable_combos) {
        let result = this.$refs.affiliate_combo.validate()
        if (result) {
          if (this.combo.type == 1) {
            if (this.combo.amount_money == 0 || this.combo.amount_money > 100) {
              this.$toasted.error("Giá trị phải lớn hơn 0 và không được vượt quá 100!",
                  {
                    position: 'top-right',
                    duration: 3000
                  })
              return false
            }
          }
          data.combos = this.combo
        }else {
          return false
        }
      }
      await this.$store.dispatch(CREATE_AFFILIATE_CODE, data);
      this.$router.push({
        path: "/student/list"
      });
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
};
</script>

<style lang="scss">
.autoCompleteBox {
  .v-input__control {
    .v-input__slot {
      width: 102%;
    }
  }
}

.checkbox-course {
  padding: 9px !important;
  position: relative;
  left: -102px;
  border: 1px solid #757575;
  background: #e0e0e0;
  border-bottom: 1px solid lightgray;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.checkbox-amount-used {
  height: 45px;
  padding: 9px !important;
  position: relative;
  left: -10%;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 1px solid lightgray; */
  margin-top: 18px;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.money-box {
  position: relative;
}

.input-money {
  .v-input__control {
    .v-input__slot {
      width: 86.5%;
    }
  }
}

.input-amount-used {
  .v-input__control {
    .v-input__slot {
      width: 107%;
    }
  }
}

.input-textArea {
  .v-input__control {
    .v-input__slot {
      width: 87.5%;
    }
  }
}

.input-hnr {
  .v-input__control {
    .v-input__slot {
      width: 91.5%;
    }
  }
}

.autoCompleteBox {
  .v-input__control {
    v-input__slot {
      width: 70%;
    }
  }
}

.money-box-input {
  width: 53%;

  .v-input__control {
    height: 66px;

    .v-input__slot {
      fieldset {
        height: 45px;
      }
    }

    .v-text-field__prefix {
      display: none;
    }

    input {
      padding-bottom: 26px;
    }
  }
}

.money__icon-fix {
  left: -43px !important;
}

.icon-hnr {
  height: 43px;
  position: relative;
  left: -12.5%;
  bottom: 17px;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 2px solid lightgray; */
  margin-bottom: -3px;
}
</style>
